"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.stop = exports.start = exports.Action = exports.Loading = void 0;
var Loading_1 = require("@shopify/app-bridge-core/actions/Loading");
Object.defineProperty(exports, "Loading", {
  enumerable: true,
  get: function () {
    return Loading_1.Loading;
  }
});
var Loading_2 = require("@shopify/app-bridge-core/actions/Loading");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Loading_2.Action;
  }
});
Object.defineProperty(exports, "start", {
  enumerable: true,
  get: function () {
    return Loading_2.start;
  }
});
Object.defineProperty(exports, "stop", {
  enumerable: true,
  get: function () {
    return Loading_2.stop;
  }
});
function create(app) {
  return new Loading_1.Loading(app);
}
exports.create = create;