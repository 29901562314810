import { AbstractControl, ValidationErrors } from '@angular/forms';

export class InputForm {
  static isFloat() {
    return (c: AbstractControl): ValidationErrors | null => {
      const regex = /^-?\d*[.]?\d{0,2}$/;
      return regex.test(c.value) ? null : { float: true };
    };
  }

  static isInteger() {
    return (c: AbstractControl): ValidationErrors | null => {
      const regex = /^\d+$/;
      return regex.test(c.value) ? null : { integer: true };
    };
  }

  static isValidUrl() {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const hasProtocol = control.value?.startsWith('http://') || control.value?.startsWith('https://');
      const urlToValidate = hasProtocol ? control.value : `http://${control.value}`;

      try {
        const url = new URL(urlToValidate);
        const isDomainValid = url.hostname.match(/^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/);
        return isDomainValid ? null : { url: true };
      } catch {
        return { url: true };
      }
    };
  }
}
