"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.Share = exports.close = exports.show = exports.Action = void 0;
var Share_1 = require("@shopify/app-bridge-core/actions/Share");
Object.defineProperty(exports, "Share", {
  enumerable: true,
  get: function () {
    return Share_1.Share;
  }
});
var Share_2 = require("@shopify/app-bridge-core/actions/Share");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Share_2.Action;
  }
});
Object.defineProperty(exports, "show", {
  enumerable: true,
  get: function () {
    return Share_2.show;
  }
});
Object.defineProperty(exports, "close", {
  enumerable: true,
  get: function () {
    return Share_2.close;
  }
});
/**
 * @public
 */
function create(app) {
  return new Share_1.Share(app);
}
exports.create = create;