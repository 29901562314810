"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateUuid = void 0;
/**
 * Convert a number or array of integers to a string of padded hex octets.
 */
function asHex(value) {
  return Array.from(value).map(function (i) {
    return ("00" + i.toString(16)).slice(-2);
  }).join('');
}
/**
 * Attempt to securely generate random bytes/
 */
function getRandomBytes(size) {
  // SPRNG
  if (typeof Uint8Array === 'function' && typeof window === 'object' && window.crypto) {
    var buffer = new Uint8Array(size);
    var randomValues = window.crypto.getRandomValues(buffer);
    if (randomValues) {
      return randomValues;
    }
  }
  // Insecure random
  return Array.from(new Array(size), function () {
    return Math.random() * 255 | 0;
  });
}
/**
 * Generate a RFC4122-compliant v4 UUID.
 *
 * @see http://www.ietf.org/rfc/rfc4122.txt
 */
function generateUuid() {
  var version = 64;
  var clockSeqHiAndReserved = getRandomBytes(1);
  var timeHiAndVersion = getRandomBytes(2);
  clockSeqHiAndReserved[0] &= 63 | 128;
  // tslint:disable-next-line:binary-expression-operand-order
  timeHiAndVersion[0] &= 15 | version;
  return [
  // time-low
  asHex(getRandomBytes(4)), '-',
  // time-mid
  asHex(getRandomBytes(2)), '-',
  // time-high-and-version
  asHex(timeHiAndVersion), '-',
  // clock-seq-and-reserved
  asHex(clockSeqHiAndReserved),
  // clock-seq-loq
  asHex(getRandomBytes(1)), '-',
  // node
  asHex(getRandomBytes(6))].join('');
}
exports.generateUuid = generateUuid;
exports.default = generateUuid;