"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Button = exports.update = exports.clickButton = exports.Style = exports.Icon = exports.Action = void 0;
var helper_1 = require("../helper");
var ActionSet_1 = require("../ActionSet");
var types_1 = require("../types");
var Action;
(function (Action) {
  Action["CLICK"] = "CLICK";
  Action["UPDATE"] = "UPDATE";
})(Action = exports.Action || (exports.Action = {}));
var Icon;
(function (Icon) {
  Icon["Print"] = "print";
})(Icon = exports.Icon || (exports.Icon = {}));
var Style;
(function (Style) {
  Style["Danger"] = "danger";
})(Style = exports.Style || (exports.Style = {}));
function clickButton(group, component, payload) {
  var id = component.id;
  var action = helper_1.getEventNameSpace(group, Action.CLICK, component);
  var buttonPayload = {
    id: id,
    payload: payload
  };
  return helper_1.actionWrapper({
    type: action,
    group: group,
    payload: buttonPayload
  });
}
exports.clickButton = clickButton;
function update(group, component, props) {
  var id = component.id;
  var label = props.label;
  var action = helper_1.getEventNameSpace(group, Action.UPDATE, component);
  var buttonPayload = __assign(__assign({}, props), {
    id: id,
    label: label
  });
  return helper_1.actionWrapper({
    type: action,
    group: group,
    payload: buttonPayload
  });
}
exports.update = update;
var Button = /** @class */function (_super) {
  __extends(Button, _super);
  function Button(app, options) {
    var _this = _super.call(this, app, types_1.ComponentType.Button, types_1.Group.Button) || this;
    _this.disabled = false;
    _this.loading = false;
    _this.plain = false;
    _this.set(options, false);
    return _this;
  }
  Object.defineProperty(Button.prototype, "options", {
    get: function () {
      return {
        disabled: this.disabled,
        icon: this.icon,
        label: this.label,
        style: this.style,
        loading: this.loading,
        plain: this.plain
      };
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Button.prototype, "payload", {
    get: function () {
      return __assign(__assign({}, this.options), {
        id: this.id
      });
    },
    enumerable: false,
    configurable: true
  });
  Button.prototype.set = function (options, shouldUpdate) {
    if (shouldUpdate === void 0) {
      shouldUpdate = true;
    }
    var mergedOptions = helper_1.getMergedProps(this.options, options);
    var label = mergedOptions.label,
      disabled = mergedOptions.disabled,
      icon = mergedOptions.icon,
      style = mergedOptions.style,
      loading = mergedOptions.loading,
      plain = mergedOptions.plain;
    this.label = label;
    this.disabled = Boolean(disabled);
    this.icon = icon;
    this.style = style;
    this.loading = Boolean(loading);
    this.plain = Boolean(plain);
    if (shouldUpdate) {
      this.dispatch(Action.UPDATE);
    }
    return this;
  };
  Button.prototype.dispatch = function (action, payload) {
    switch (action) {
      case Action.CLICK:
        this.app.dispatch(clickButton(this.group, this.component, payload));
        break;
      case Action.UPDATE:
        {
          var updateAction = update(this.group, this.component, this.payload);
          this.app.dispatch(updateAction);
          break;
        }
    }
    return this;
  };
  return Button;
}(ActionSet_1.ActionSet);
exports.Button = Button;