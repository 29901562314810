"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.ResourcePicker = exports.WeightUnit = exports.update = exports.select = exports.ResourceType = exports.ProductVariantInventoryPolicy = exports.ProductVariantInventoryManagement = exports.ProductStatus = exports.open = exports.FulfillmentServiceType = exports.CollectionSortOrder = exports.close = exports.cancel = exports.ActionVerb = exports.Action = void 0;
var ResourcePicker_1 = require("@shopify/app-bridge-core/actions/ResourcePicker");
Object.defineProperty(exports, "ResourcePicker", {
  enumerable: true,
  get: function () {
    return ResourcePicker_1.ResourcePicker;
  }
});
var ResourcePicker_2 = require("@shopify/app-bridge-core/actions/ResourcePicker");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.Action;
  }
});
Object.defineProperty(exports, "ActionVerb", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.ActionVerb;
  }
});
Object.defineProperty(exports, "cancel", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.cancel;
  }
});
Object.defineProperty(exports, "close", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.close;
  }
});
Object.defineProperty(exports, "CollectionSortOrder", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.CollectionSortOrder;
  }
});
Object.defineProperty(exports, "FulfillmentServiceType", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.FulfillmentServiceType;
  }
});
Object.defineProperty(exports, "open", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.open;
  }
});
Object.defineProperty(exports, "ProductStatus", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.ProductStatus;
  }
});
Object.defineProperty(exports, "ProductVariantInventoryManagement", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.ProductVariantInventoryManagement;
  }
});
Object.defineProperty(exports, "ProductVariantInventoryPolicy", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.ProductVariantInventoryPolicy;
  }
});
Object.defineProperty(exports, "ResourceType", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.ResourceType;
  }
});
Object.defineProperty(exports, "select", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.select;
  }
});
Object.defineProperty(exports, "update", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.update;
  }
});
Object.defineProperty(exports, "WeightUnit", {
  enumerable: true,
  get: function () {
    return ResourcePicker_2.WeightUnit;
  }
});
var create = function (app, baseOptions) {
  var resourceType = baseOptions.resourceType,
    _a = baseOptions.options,
    options = _a === void 0 ? {} : _a;
  return new ResourcePicker_1.ResourcePicker(app, options, resourceType);
};
exports.create = create;