"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.Pos = exports.close = exports.Action = void 0;
var Pos_1 = require("@shopify/app-bridge-core/actions/Pos");
Object.defineProperty(exports, "Pos", {
  enumerable: true,
  get: function () {
    return Pos_1.Pos;
  }
});
var Pos_2 = require("@shopify/app-bridge-core/actions/Pos");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Pos_2.Action;
  }
});
Object.defineProperty(exports, "close", {
  enumerable: true,
  get: function () {
    return Pos_2.close;
  }
});
function create(app) {
  return new Pos_1.Pos(app);
}
exports.create = create;