import { mainTableColumnDefaultGroup } from '../enums';
import { ColumnTypesEnum, innerColumnName } from '../table-display/ColumnTypesEnum';
import { ColumnGroupModel } from '../table-display/columns-switch/columns/ColumnGroup.model';
import { InnerColumn } from '../table-display/columns-switch/columns/InnerColumn';
import { ColumnModel } from '../table-display/columns-switch/columns/column.model';
import { TableConfigurationInterface } from './TableConfigurationInterface.model';

export class TableConfigurationModel extends TableConfigurationInterface {
  indexFirstResizingElement!: number;

  constructor(data: TableConfigurationInterface) {
    super();

    this.actionsButtonSlot = data.actionsButtonSlot ? data.actionsButtonSlot : [];
    this.activeFilters = data.activeFilters;
    this.columnGroups = data.columnGroups ? [...data.columnGroups] : [];
    this.columns = data.columns;
    this.configuration = data.configuration;
    this.displayedColumns = data.displayedColumns ? [...data.displayedColumns] : [];
    this.groupedBy = data.groupedBy;
    this.inactiveStateDependencyColumn = data.inactiveStateDependencyColumn;
    this.initFilters = data.initFilters;
    this.itemRowStyle = data.itemRowStyle || null;
    this.itemsPerPage = data.itemsPerPage;
    this.mainToolbarSlot = data.mainToolbarSlot ? data.mainToolbarSlot : [];
    this.memory = data.memory || {};
    this.navigationColumn = data.navigationColumn;
    this.newItemRow = data.newItemRow;
    this.readOnly = data.readOnly || false;
    this.requiredFields = data.requiredFields || null!;
    this.selectedGloballyToolbarSlot = data.selectedGloballyToolbarSlot ? data.selectedGloballyToolbarSlot : [];
    this.selectedToolbarDropdown = data.selectedToolbarDropdown;
    this.selectedToolbarSlot = data.selectedToolbarSlot ? data.selectedToolbarSlot : [];
    this.sorting = data.sorting;
    this.tools = { ...data.tools };
    this.tools.tabs = this.tools.tabs ? this.tools.tabs : [];

    data.actionColumn && (this.actionColumn = data.actionColumn);
    data.paginator && (this.paginator = data.paginator);

    this.columns[innerColumnName] = new InnerColumn();

    this.columnGroups.push(new ColumnGroupModel(mainTableColumnDefaultGroup));

    this.calculateIndexForResizingElement();

    Object.keys(this.columns).forEach((key) => {
      const column: ColumnModel = this.columns[key];
      const group: ColumnGroupModel = this.columnGroups!.find((columnGroup) => columnGroup.alias === column.group)!;

      column.param = key;
      if (group) {
        group.applyOnColumn(column);
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Column Group: ${column.group} does not exist in configuration: ${this.configuration.storageName}`);
      }
    });
  }

  getColumn(columnName: string): ColumnModel {
    return this.columns[columnName];
  }

  returnColumnsWithType(type: ColumnTypesEnum): string[] {
    return Object.keys(this.columns).filter((key) => this.columns[key].type === type);
  }

  returnColumnsAbleToFilter(): string[] {
    return Object.keys(this.columns).filter((key) => this.columns[key].ableToFilter);
  }

  isInnerColumnIncluded(): boolean {
    const { select, actionButtons } = this.tools;
    return !!select || !!(actionButtons && (actionButtons.activeAlways || actionButtons.dependsOnParam));
  }

  private calculateIndexForResizingElement(): number {
    let i = 1;
    i = this.isInnerColumnIncluded() ? i + 1 : i;
    return (this.indexFirstResizingElement = i);
  }
}
