"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSingleButton = void 0;
var buttonHelper_1 = require("@shopify/app-bridge-core/actions/buttonHelper");
Object.defineProperty(exports, "getSingleButton", {
  enumerable: true,
  get: function () {
    return buttonHelper_1.getSingleButton;
  }
});