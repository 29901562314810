import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterGroupModel } from '../filters/models/filter.model';
import { TableConfigurationModel } from '../models/TableConfiguration.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService implements OnDestroy {
  config!: TableConfigurationModel;
  config$: Subject<TableConfigurationModel> = new Subject();
  private onDestroy$: Subject<void> = new Subject();

  constructor() {
    this.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => (this.config = config));
  }

  saveConfig(data: TableConfigurationModel) {
    if (!data) {
      return;
    }
    this.config$.next(data);
  }

  changeFilters(activeFilters: FilterGroupModel[], filterManualChanges: boolean | null) {
    if (this.config) {
      this.config.activeFilters = {
        filterGroups: activeFilters,
        filterManualChanges: filterManualChanges,
      };
      this.config$.next(this.config);
    }
  }

  changeSorting(sorting: any) {
    this.config.sorting = sorting;
    this.config$.next(this.config);
  }

  changeGroupBy(groupedBy: string[]) {
    this.config.groupedBy = groupedBy;
    this.config$.next(this.config);
  }

  changePagination(size: number) {
    this.config.itemsPerPage = size;
    this.config$.next(this.config);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
