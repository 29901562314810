"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.Features = void 0;
var Features_1 = require("@shopify/app-bridge-core/actions/Features");
Object.defineProperty(exports, "Features", {
  enumerable: true,
  get: function () {
    return Features_1.Features;
  }
});
/**
 * @public
 */
function create(app, options) {
  return new Features_1.Features(app, options);
}
exports.create = create;