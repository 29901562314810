"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.request = exports.Action = exports.respond = void 0;
var AuthCode_1 = require("@shopify/app-bridge-core/actions/AuthCode");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return AuthCode_1.Action;
  }
});
var helper_1 = require("../helper");
var types_1 = require("../types");
var AuthCode_2 = require("@shopify/app-bridge-core/actions/AuthCode");
Object.defineProperty(exports, "respond", {
  enumerable: true,
  get: function () {
    return AuthCode_2.respond;
  }
});
function request(id) {
  return helper_1.actionWrapper({
    group: types_1.Group.AuthCode,
    type: AuthCode_1.Action.REQUEST,
    payload: {
      id: id
    }
  });
}
exports.request = request;