"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = void 0;
/**
 * Action Types for the Features group
 * @public
 */
var Action;
(function (Action) {
  Action["UPDATE"] = "APP::FEATURES::UPDATE";
  Action["REQUEST"] = "APP::FEATURES::REQUEST";
  /**
   * @deprecated as of 2.1.0. Use "APP::FEATURES::UPDATE" instead
   */
  Action["REQUEST_UPDATE"] = "APP::FEATURES::REQUEST::UPDATE";
})(Action = exports.Action || (exports.Action = {}));