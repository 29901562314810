"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeFromCollection = exports.addAndRemoveFromCollection = void 0;
/**
 * Add an item to a collection, return a function that can then be used to
 * remove the item from the collection. Optionally accepting a callback that is
 * invoked when the item is removed from the collection.
 *
 * @internal
 */
function addAndRemoveFromCollection(collection, item, then) {
  collection.push(item);
  return function () {
    return removeFromCollection(collection, item, then);
  };
}
exports.addAndRemoveFromCollection = addAndRemoveFromCollection;
/**
 * Remove the item from the collection. Optionally accepting a callback that is
 * invoked when the item is removed from the collection.
 *
 * @internal
 */
function removeFromCollection(collection, item, then) {
  var idx = collection.findIndex(function (i) {
    return i === item;
  });
  if (idx >= 0) {
    collection.splice(idx, 1);
    if (then) {
      then(item);
    }
    return true;
  }
  return false;
}
exports.removeFromCollection = removeFromCollection;