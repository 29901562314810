export enum FilterTypesEnum {
  is_empty = 'is_empty',
  not_empty = 'not_empty',
  equal = 'equal',
  include = 'include',
  include_one_of = 'include_one_of',
  exclude = 'exclude',
  exists_in = 'in',
  not_exists_in = 'not_in',
  in_array = 'in_array',
  start = 'start',
  end = 'end',
  regexp = 'regexp',
  num_equal = '=',
  num_bigger = '>',
  string_bigger = '>',
  num_biggerrEqual = '>=',
  num_smaller = '<',
  string_smaller = '<',
  num_smallerEqual = '<=',
  not_equal = '!=',
  is_uppercase = 'is_uppercase',
  field_not_include_field = 'attribute_not_include_attribute',
  has_manual_changes = 'has_manual_changes',
}
