export interface DataNotification {
  task_id: number;
  type?: string;
  priority?: string;
  message_type?: string;
  task_account_id?: number;
}

export interface MessageModel {
  id: number;
  user_id: number;
  message: string;
  created_at: string;
  updated_at: string;
  type: MessageTypeEnum;
  status: number;
  isReaded?: boolean;
  data?: DataNotification;
  project_id?: number;
  project_name?: string;
  message_type?: string;
}

export enum MessageTypeEnum {
  updates = 6,
  info = 5,
  tasks = 4,
  error = 3,
  warning = 2,
  success = 1,
}

export enum NotificationMessageTypeEnum {
  CHANGED_PRODUCTS_CREATED_LAST_LOGIN = 'changed_products.created.last_login',
  CHANGED_PRODUCTS_CREATED_LAST_PROJECT_VIEW = 'changed_products.created.last_project_view',
  CHANGED_PRODUCTS_CREATED_MANUAL_DATE = 'changed_products.created.manual_date',

  CHANGED_PRODUCTS_UPDATED_LAST_LOGIN = 'changed_products.updated.last_login',
  CHANGED_PRODUCTS_UPDATED_LAST_PROJECT_VIEW = 'changed_products.updated.last_project_view',
  CHANGED_PRODUCTS_UPDATED_MANUAL_DATE = 'changed_products.updated.manual_date',

  CHANGED_PRODUCTS_DELETED_FROM_FEED_LAST_LOGIN = 'changed_products.deleted_from_feed.last_login',
  CHANGED_PRODUCTS_DELETED_FROM_FEED_LAST_PROJECT_VIEW = 'changed_products.deleted_from_feed.last_project_view',
  CHANGED_PRODUCTS_DELETED_FROM_FEED_MANUAL_DATE = 'changed_products.deleted_from_feed.manual_date',

  FIRST_CRAWL_COMPLETE = 'first_crawl_complete',
  FULL_CRAWL_COMPLETE = 'full_crawl_complete',
  DOWNLOAD_FEED_ERROR = 'download_feed_error',
  CAMPAIGN_SYNC_ERROR = 'campaign_sync_error',
  PRODUCTS_DIAGNOSTIC_SCAN_COMPLETE = 'products_diagnostic_scan_complete',
}
