import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import ApiUrls from '../../configs/api-urls.config';
import { ProfileModel } from '../model/profile.model';
import { AuthProvidersInterface, ResponseInterface, ResponseV2Interface } from '../model/response.model';
import { AccountInterface, UserModel } from '../model/user.model';
import { FilterGroupModel } from '../sem-table/filters/models/filter.model';
import { LoaderService } from './loader.service';
import { LocalStorageService, storageKey } from './local-storage.service';
import { RestrictedModeService } from './restricted-mode.service';
import { SettingsService } from './settings.service';

const TOKEN_STORAGE_KEY = storageKey.token;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  authUser: UserModel | null = null;
  authUser$ = new Subject<UserModel>();
  customPageAfterLogin: string | null = null;

  private permissionsSubject$ = new BehaviorSubject<string[]>([]);
  permissions$ = this.permissionsSubject$.asObservable().pipe(distinctUntilChanged());

  private onDestroy$ = new Subject<void>();
  private readonly currentTokenSubject = new BehaviorSubject<string | null>(this.storageHelperService.get(TOKEN_STORAGE_KEY));

  readonly currentToken$ = this.currentTokenSubject.asObservable();
  private currentToken: string | null = null;

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private restrictedModeService: RestrictedModeService,
    private router: Router,
    private settingsService: SettingsService,
    private storageHelperService: LocalStorageService,
  ) {
    this.authUser$
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => this.loaderService.on()),
        tap((user) => (user.permissions = user.email_verified ? user.permissions : [])),
        tap((user) => this.permissionsSubject$.next(user.permissions || [])),
        tap((user) => (this.authUser = user)),
        tap((user: UserModel) => {
          if (user) {
            this.loaderService.on();
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { account_type, connections_count, email_verified, is_payment_required, projects_count, superuser } = user;

            if (superuser) {
              // Tryb restrycyjny nie dotyczy konta super admina
              this.restrictedModeService.turnOffRestrictiveMode();
            } else {
              if (!email_verified) {
                this.restrictedModeService.setEmailMode();
              } else if (is_payment_required) {
                this.restrictedModeService.setPaymentMode();
              } else if (!projects_count) {
                this.restrictedModeService.setProjectMode();
              } else if (account_type === 'sso' && !connections_count) {
                // Indywidualna ściezka dla usera z chata (w tej chwili type===sso)
                this.restrictedModeService.setConnectionsMode();
              } else {
                this.restrictedModeService.final(account_type!);
              }
            }
          }
        }),
      )
      .subscribe();
  }

  get token() {
    if (!isShopifyEmbedded()) {
      return this.storageHelperService.get(TOKEN_STORAGE_KEY);
    }
    return this.currentToken;
  }

  saveToken(token: string) {
    if (!isShopifyEmbedded()) {
      this.storageHelperService.save(TOKEN_STORAGE_KEY, token);
    }
    this.currentTokenSubject.next(token);
    this.currentToken = token;
  }

  removeToken() {
    if (!isShopifyEmbedded()) {
      this.storageHelperService.remove(TOKEN_STORAGE_KEY);
    }
    this.currentTokenSubject.next(null);
    this.currentToken = null;
  }

  register(
    // TMP: #reductionOfFormFields
    email: string,
    password: string,
    passwordConfirmation: string,
    // name: string,
    regs: boolean,
    // allowBrandUse: boolean,
    accountType: string,
    source: string,
    coupon: string,
    allow_brand_use?: boolean,
  ): Observable<void> {
    return this.http
      .post<void>(ApiUrls.register, {
        coupon,
        email,
        password,
        password_confirmation: passwordConfirmation,
        regs,
        source,
        allow_brand_use,
        // TMP: #reductionOfFormFields
        account_type: accountType,
        // allow_brand_use: allowBrandUse,
        // name,
      })
      .pipe();
  }

  login(email: string, password: string): Observable<string> {
    return this.http
      .post<ResponseInterface<string>>(ApiUrls.login, {
        email,
        password,
      })
      .pipe(
        tap((response) => this.saveToken(response.data.item!)),
        map((response) => response.data.item!),
      );
  }

  logout(clearAll: boolean = true, reload: boolean = false) {
    try {
      this.http
        .post(ApiUrls.logout, {})
        .pipe(tap(() => (clearAll ? this.handleClearAll() : this.removeToken())))
        .subscribe(
          () => this.redirect(reload),
          () => {
            this.handleClearAll();
            this.redirect(false);
          },
        );
    } catch (err) {
      this.handleClearAll();
      this.redirect(false);
    }
  }

  changePassword(password: string, password_confirmation: string): Observable<void> {
    const { token } = this;
    return this.http
      .post<void>(ApiUrls.passwordNew, {
        password,
        password_confirmation,
        token,
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(error.error)));
  }

  remindPassword(email: string) {
    return this.http
      .post<ResponseInterface<UserModel>>(ApiUrls.passwordSendMail, {
        email,
      })
      .pipe(
        // map(response => response.data.item),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
      );
  }

  resetPassword(token: string, password: string, passwordConfirmation: string) {
    return this.http
      .post<ResponseInterface<UserModel>>(ApiUrls.passwordReset, {
        token,
        password,
        password_confirmation: passwordConfirmation,
      })
      .pipe(
        // map(response => response.data.item),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
      );
  }

  changeActiveAccount(accountId: number, redirect = true): Observable<ProfileModel> {
    return this.http
      .patch<ResponseV2Interface<ProfileModel>>(ApiUrls.profile, {
        account_id: accountId,
      })
      .pipe(
        map((res) => res.data),
        tap((res) => this.authUser$.next(res.user)),
        tap((res) => this.settingsService.changeSettingsInApp(res.settings)),
        tap(() => redirect && this.router.navigate([''])),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
      );
  }

  addMyAccount(): Observable<number> {
    return this.http.put<ResponseV2Interface<ProfileModel>>(ApiUrls.profile, null).pipe(
      map((res) => res.data.user),
      tap((user) => this.authUser$.next(user)),
      map((user) => user.my_account!),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
    );
  }

  allUsers(data: { order: string; direction: string; filters: FilterGroupModel[] }): Observable<AccountInterface[]> {
    return this.http.post<ResponseV2Interface<AccountInterface[]>>(ApiUrls.users, data).pipe(
      map((res) => res.data),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
    );
  }

  confirmEmail(id: string): Observable<any> {
    return this.http
      .get(ApiUrls.emailVerify.prepareUrl({ token: id }))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error.error)));
  }

  resendConfirmEmail(): Observable<void> {
    return this.http.get<void>(ApiUrls.emailResend).pipe(catchError((error: HttpErrorResponse) => throwError(error.error)));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  getAccountId(): Observable<number> {
    return this.authUser$.pipe(
      map((user) => user.my_account!),
      distinctUntilChanged(),
    );
  }

  getExternalProviders(): Observable<AuthProvidersInterface[]> {
    return this.http.get<{ data: AuthProvidersInterface[] }>(ApiUrls.externalProviders).pipe(
      map((response) => response.data),
      catchError((error: HttpErrorResponse) => throwError(() => error.error)),
    );
  }

  private redirect(reload: boolean = false) {
    reload ? window.location.reload() : this.router.navigate(['']);
  }

  private handleClearAll() {
    this.storageHelperService.clear();
    this.removeToken();
  }
}
