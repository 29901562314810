"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.respond = exports.request = exports.Action = void 0;
var SessionToken_1 = require("@shopify/app-bridge-core/actions/SessionToken");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return SessionToken_1.Action;
  }
});
Object.defineProperty(exports, "request", {
  enumerable: true,
  get: function () {
    return SessionToken_1.request;
  }
});
Object.defineProperty(exports, "respond", {
  enumerable: true,
  get: function () {
    return SessionToken_1.respond;
  }
});