"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fullPageLoad = exports.skeletonPageLoad = exports.Action = void 0;
var Performance_1 = require("@shopify/app-bridge-core/actions/Performance");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Performance_1.Action;
  }
});
Object.defineProperty(exports, "skeletonPageLoad", {
  enumerable: true,
  get: function () {
    return Performance_1.skeletonPageLoad;
  }
});
Object.defineProperty(exports, "fullPageLoad", {
  enumerable: true,
  get: function () {
    return Performance_1.fullPageLoad;
  }
});