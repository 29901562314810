import { RouterConfigKey } from 'src/app/configs/router.config';
import { OperatorEnum } from '../enums/permissions.enum';
import { Permission } from './permission';

export enum SuperiorPermissionEnum {
  superuser = 'superuser',
}

export interface RouteInterface {
  areVariable?: boolean;
  isUnavailableForWhiteLabels?: boolean;
  key?: RouterConfigKey;
  permissions?: Permission[];
  permissionsOperator?: OperatorEnum;
  superiorPermission?: SuperiorPermissionEnum;
  url: string;
  urlMask?: string;

  // Additional features
  isBetaFeature?: boolean;
}
