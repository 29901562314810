"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.app = exports.Action = void 0;
var Print_1 = require("@shopify/app-bridge-core/actions/Print");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Print_1.Action;
  }
});
Object.defineProperty(exports, "app", {
  enumerable: true,
  get: function () {
    return Print_1.app;
  }
});