import { Component, Input } from '@angular/core';
import { EditableColumnComponent } from '../../sem-table/table-display/columns-switch/columns/editable-column.component';
import { BADGE_SETS } from './badge-sets';
import { BadgeInterface, BadgeTypeEnum, SetType } from './badge.model';
import { SummariesStatus } from '../../../dashboard/project/summaries/summaries.enum';

const DEFAULT_IS_OUTLINED = false;
const DEFAULT_TYPE = BadgeTypeEnum.dark;

type BadgeEditingListItem = { key: string; translateKey: string | undefined }[];

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent extends EditableColumnComponent {
  @Input() isOutlined: boolean = DEFAULT_IS_OUTLINED;
  @Input() translateKey!: string;
  @Input() type: BadgeTypeEnum = DEFAULT_TYPE;

  editingList!: BadgeEditingListItem;
  isColumnInEditState = false;
  valueKey!: string;
}

@Component({
  selector: 'app-badge-set',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeSetComponent extends EditableColumnComponent {
  editingList!: BadgeEditingListItem;
  isOutlined: boolean = DEFAULT_IS_OUTLINED;
  type: BadgeTypeEnum = DEFAULT_TYPE;
  valueKey!: string;
  @Input() set!: SetType;
  private defaultTranslateKey: any;

  private _translateKey!: string;

  get translateKey(): string {
    return this._translateKey || this.defaultTranslateKey;
  }

  @Input() set translateKey(key: string) {
    this._translateKey = key;

    if (this.set === 'summaries-status' && this.valueKey === SummariesStatus.pause) {
      this._translateKey = 'summaries.status.pause';
    }
  }

  @Input() set key(key: string) {
    this.valueKey = key;
    const setData = BADGE_SETS[this.set] || null;
    this.editingList = Object.keys(setData).map((dataKey) => ({ key: dataKey, translateKey: setData[dataKey].translateKey }));

    const badgeData: BadgeInterface = (setData && setData[key]) || null;

    if (badgeData) {
      const { isOutlined, translateKey, type } = badgeData;

      isOutlined && (this.isOutlined = isOutlined);
      this.defaultTranslateKey = translateKey || key;
      type && (this.type = type);
    }
  }
}
