"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGroupedButton = void 0;
var ButtonGroup_1 = require("./ButtonGroup");
function getGroupedButton(action, button, subgroups, updateCb) {
  action.addChild(button, action.group, subgroups);
  var id = button.id,
    label = button.label,
    disabled = button.disabled,
    buttons = button.buttons,
    plain = button.plain;
  action.subscribeToChild(button, ButtonGroup_1.Action.UPDATE, updateCb);
  return {
    id: id,
    label: label,
    buttons: buttons,
    disabled: disabled,
    plain: plain
  };
}
exports.getGroupedButton = getGroupedButton;