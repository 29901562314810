"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialize = exports.Action = void 0;
var Client_1 = require("@shopify/app-bridge-core/actions/Client");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Client_1.Action;
  }
});
var types_1 = require("../types");
var helper_1 = require("../helper");
function initialize() {
  return helper_1.actionWrapper({
    group: types_1.Group.Client,
    type: Client_1.Action.INITIALIZE
  });
}
exports.initialize = initialize;