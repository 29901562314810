"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.NavigationMenu = exports.update = exports.Action = void 0;
var NavigationMenu_1 = require("@shopify/app-bridge-core/actions/Menu/NavigationMenu");
Object.defineProperty(exports, "NavigationMenu", {
  enumerable: true,
  get: function () {
    return NavigationMenu_1.NavigationMenu;
  }
});
var NavigationMenu_2 = require("@shopify/app-bridge-core/actions/Menu/NavigationMenu");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return NavigationMenu_2.Action;
  }
});
Object.defineProperty(exports, "update", {
  enumerable: true,
  get: function () {
    return NavigationMenu_2.update;
  }
});
function create(app, options) {
  return new NavigationMenu_1.NavigationMenu(app, options);
}
exports.create = create;