<h2 mat-dialog-title>{{ dataMessage() }}</h2>

<mat-dialog-content class="error-content">
  @if (errorKeys().length > 0) {
    @for (key of errorKeys(); track key) {
      <div class="error-group">
        <strong>{{ key }}</strong>
        <ul>
          @for (err of dataErrors()[key]; track err) {
            <li>{{ err }}</li>
          }
        </ul>
      </div>
    }
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button (click)="onClose()" color="primary" mat-raised-button>
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>
