"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.ChannelMenu = exports.update = exports.Action = void 0;
var ChannelMenu_1 = require("@shopify/app-bridge-core/actions/Menu/ChannelMenu");
Object.defineProperty(exports, "ChannelMenu", {
  enumerable: true,
  get: function () {
    return ChannelMenu_1.ChannelMenu;
  }
});
var ChannelMenu_2 = require("@shopify/app-bridge-core/actions/Menu/ChannelMenu");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return ChannelMenu_2.Action;
  }
});
Object.defineProperty(exports, "update", {
  enumerable: true,
  get: function () {
    return ChannelMenu_2.update;
  }
});
function create(app, options) {
  return new ChannelMenu_1.ChannelMenu(app, options);
}
exports.create = create;