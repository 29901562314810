"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.FeedbackModal = exports.close = exports.open = exports.Action = void 0;
var FeedbackModal_1 = require("@shopify/app-bridge-core/actions/FeedbackModal");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.Action;
  }
});
Object.defineProperty(exports, "open", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.open;
  }
});
Object.defineProperty(exports, "close", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.close;
  }
});
Object.defineProperty(exports, "FeedbackModal", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.FeedbackModal;
  }
});
Object.defineProperty(exports, "create", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.create;
  }
});