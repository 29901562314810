"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWindow = exports.getLocation = exports.redirect = exports.shouldRedirect = void 0;
function shouldRedirect(frame) {
  return frame === window;
}
exports.shouldRedirect = shouldRedirect;
function redirect(url) {
  var location = getLocation();
  if (!location) {
    return;
  }
  location.assign(url);
}
exports.redirect = redirect;
function getLocation() {
  return hasWindow() ? window.location : undefined;
}
exports.getLocation = getLocation;
function getWindow() {
  return hasWindow() ? window : undefined;
}
exports.getWindow = getWindow;
function hasWindow() {
  return typeof window !== 'undefined';
}