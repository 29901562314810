import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OperatorEnum } from '../enums/permissions.enum';
import { Permission } from '../model/permission';
import { SuperiorPermissionEnum } from '../model/router-config.model';
import { AuthService } from '../service/auth.service';
import { RouterService } from '../service/router.service';

@Directive({
  selector: '[appHasPermissions]',
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  onDestroy$: Subject<void> = new Subject();
  private isHidden = true;
  private logicalOp: OperatorEnum = OperatorEnum.AND;
  private permissions: Permission[] = [];
  private superiorPermission: SuperiorPermissionEnum | null = null;

  constructor(
    private authService: AuthService,
    private routerService: RouterService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input()
  set appHasPermissions(val: Permission[]) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set appHasPermissionsOp(permop: string) {
    if (permop && (OperatorEnum as any)[permop]) {
      this.logicalOp = (OperatorEnum as any)[permop];
      this.updateView();
    }
  }

  @Input()
  set appHasPermissionsSuperior(superiorPermission: SuperiorPermissionEnum) {
    this.superiorPermission = superiorPermission;
  }

  ngOnInit() {
    this.authService.permissions$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.updateView());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private updateView() {
    if (this.routerService.appHasPermissions(this.permissions, this.logicalOp, this.superiorPermission || null)) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
      console.warn(`User lacks permissions: ${this.permissions}`);
    }
  }
}
