"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ComponentType = exports.Group = void 0;
var types_1 = require("@shopify/app-bridge-core/actions/types");
Object.defineProperty(exports, "Group", {
  enumerable: true,
  get: function () {
    return types_1.Group;
  }
});
Object.defineProperty(exports, "ComponentType", {
  enumerable: true,
  get: function () {
    return types_1.ComponentType;
  }
});