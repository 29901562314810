"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.TitleBar = exports.update = exports.clickBreadcrumb = exports.clickActionButton = exports.Action = void 0;
var TitleBar_1 = require("@shopify/app-bridge-core/actions/TitleBar");
Object.defineProperty(exports, "TitleBar", {
  enumerable: true,
  get: function () {
    return TitleBar_1.TitleBar;
  }
});
var TitleBar_2 = require("@shopify/app-bridge-core/actions/TitleBar");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return TitleBar_2.Action;
  }
});
Object.defineProperty(exports, "clickActionButton", {
  enumerable: true,
  get: function () {
    return TitleBar_2.clickActionButton;
  }
});
Object.defineProperty(exports, "clickBreadcrumb", {
  enumerable: true,
  get: function () {
    return TitleBar_2.clickBreadcrumb;
  }
});
Object.defineProperty(exports, "update", {
  enumerable: true,
  get: function () {
    return TitleBar_2.update;
  }
});
function create(app, options) {
  return new TitleBar_1.TitleBar(app, options);
}
exports.create = create;