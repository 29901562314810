import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { filter, find, groupBy, mapValues, map as mapp, pick } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import ApiUrls from 'src/app/configs/api-urls.config';
import { PluginService } from 'src/app/shared/service/plugin.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { ResponseV2Interface } from '../../shared/model/response.model';
import { PaymentPlanSupportEnum } from './models/payments.enum';
import {
  CurrencyEnum,
  CurrentPackage,
  PaymentAdditionalProduct,
  PaymentDataModel,
  PaymentElementModel,
  PaymentModel,
  PaymentPlanModel,
  PaymentPlanProductsModel,
  SubscriptionGuestPriceRequest,
  SubscriptionPriceModel,
  SubscriptionPriceRequest,
  SubscriptionPriceResponse,
} from './models/payments.model';
import { StripeStatusEnum } from './models/stripe-status.enum';

const PRODUCT_FIELDS = ['id', 'price', 'prod_name', 'restrictions', 'setup', 'trial'];
const PROD_NAME = (obj: any) => (obj.prod_name ? obj.prod_name.replace(/ /g, '_') : null);

export interface AdditionalProductsResponse {
  [key: string]: {
    id: number;
    price: number;
    currency: string;
    amount: number;
    restrictions: {
      [key: string]: string;
    };
  }[];
}

const FREE_PACKAGE: PaymentModel = {
  id: 0,
  price: 0,
  currency: CurrencyEnum.pln,
  modules: ['products', 'projects'],
  restrictions: {
    projects: '0',
    output_products: '0',
    monitor_phrases: '0',
    monitor_products: '0',
    campaigns: 0,
  },
  package_name: 'Free',
  description: '',
  products: [],
  tax: 0,
  trial: false,
  elements: {},
};

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  currentPackage$ = new BehaviorSubject<CurrentPackage | null>(null);
  availablePackages!: PaymentModel[];
  private elementsToDisplay = ['projects', 'campaigns', 'output_products', 'products'];

  private readonly http = inject(HttpClient);
  private readonly pluginService = inject(PluginService);
  private readonly translationService = inject(TranslationService);

  getAccountCoupon(): Observable<string> {
    return this.http.get<ResponseV2Interface<{ coupon: string }>>(ApiUrls.paymentsCoupon).pipe(map((res) => res.data.coupon));
  }

  getCurrentPrice() {
    return this.http.get<{ current_price: number; currency: string }>(ApiUrls.paymentsSubscriptionCurrentPrice);
  }

  getNextPaymentPrice() {
    return this.http.get<ResponseV2Interface<{ amount: number }>>(ApiUrls.paymentsSubscriptionNextPaymentPrice);
  }

  getAdditionalProducts() {
    return this.http.get<ResponseV2Interface<AdditionalProductsResponse>>(ApiUrls.paymentsGetAdditionalProducts).pipe(
      map((res) => res.data),
      tap((res) =>
        Object.keys(res).forEach((key) => res[key].forEach((element) => (element.amount = +Object.values(element.restrictions)[0]))),
      ),
    );
  }

  // TODO: do przeoorania - zrezygnowaliśmy z typowych pakietów kosztem jednego konfigurowanego planu -
  // prawdopodobnie dane pakietowe dla pluginu będa do zmiany!!
  getAvailablePackages(): Observable<PaymentModel[]> {
    const request = this.pluginService.isPluginMode
      ? this.http.get<ResponseV2Interface<PaymentModel[]>>(ApiUrls.paymentsPackagesShopify, {})
      : this.http.get<ResponseV2Interface<PaymentModel[]>>(ApiUrls.paymentsPackages);

    return this.availablePackages
      ? of(this.availablePackages)
      : request.pipe(
          map((res) => res.data),
          map((res) => res.sort((a, b) => a.price - b.price)),
          tap((res) => (this.pluginService.isPluginMode ? null : res.unshift(FREE_PACKAGE))),
          tap((res) => res.map((el) => this.parsePackage(el))),
          tap((res) => (this.availablePackages = res)),
        );
  }

  getPlan(currency: string | null = null, country: string | null = null): Observable<PaymentPlanModel> {
    let params = new HttpParams();
    if (currency) {
      params = params.set('currency', currency);
    }

    if (country) {
      params = params.set('country', country.toUpperCase());
    }

    return this.http.get<ResponseV2Interface<PaymentPlanModel>>(ApiUrls.paymentsPackages, { params }).pipe(
      map((res) => res.data),
      map((data) => this.preparePlan(data)),
    );
  }

  getAvailablePackageById(id: number): Observable<PaymentModel> {
    // TODO: podpiąć pod nową zwrotkę???
    const packages$ = this.getAvailablePackages();
    return packages$.pipe(map((res) => res.filter((pack) => pack.id === id)[0]));
  }

  getSubscriptionGuestPrice(
    currency: CurrencyEnum,
    coupon: string | null = null,
    products: PaymentAdditionalProduct[] = [],
    country: string | null = null,
  ): Observable<SubscriptionPriceModel> {
    const body: SubscriptionGuestPriceRequest = { coupon: coupon || undefined, currency, products };
    if (country) {
      body.country = country.toUpperCase();
    }

    return this.http.post<SubscriptionPriceResponse>(ApiUrls.paymentsSunscriptionGuestPrice, body).pipe(map((res) => res.data));
  }

  getSubscriptionPrice(
    coupon: string | null = null,
    products: PaymentAdditionalProduct[] = [],
    country: string | null = null,
  ): Observable<SubscriptionPriceModel> {
    const body: SubscriptionPriceRequest = { coupon: coupon || undefined, products };

    if (country) {
      body.country = country.toUpperCase();
    }

    return this.http.post<any>(ApiUrls.paymentsSubscriptionPrice, body).pipe(map((res) => res.data));
  }

  // additional: [{ id: number, count: number }] = [])
  getPaymentCheckoutUrl(subscriptionId: unknown, additional: unknown[] = [], country: string | null = null): Observable<string> {
    const body: any = {
      subscription_pricing_id: subscriptionId,
      additional_pricing: additional,
    };

    if (country) {
      body.country = country.toUpperCase();
    }

    return this.http.post<any>(ApiUrls.paymentsCheckout, body).pipe(map((res) => res.data?.checkout || null));
  }

  buyPackage(packageId: number, paymentMethod: string, coupon: string | null = null): Observable<any> {
    return this.http.post<any>(ApiUrls.paymentsSunscriptionBuy, { package_id: packageId, payment_method: paymentMethod, coupon });
  }

  getCurrentPackage(): Observable<CurrentPackage> {
    const url = this.pluginService.isPluginMode ? ApiUrls.paymentsCurrentPackageShopify : ApiUrls.paymentsCurrentPackage;
    return this.http.get<ResponseV2Interface<CurrentPackage>>(url).pipe(
      map((res) => res.data),
      // map((res) => res.data),
      tap((current) => this.mapCurrentPackage(current)),
      tap((current) => this.currentPackage$.next(current)),
    );
  }

  getCurrentPackageSuperuser(): Observable<CurrentPackage> {
    return this.http.get<ResponseV2Interface<CurrentPackage>>(ApiUrls.superuserCurrentPackage).pipe(
      map((res) => res.data),
      tap((current) => this.mapCurrentPackage(current)),
      tap((current) => this.currentPackage$.next(current)),
    );
  }

  cancelSubscription(): Observable<PaymentModel> {
    return this.http.post<PaymentModel>(ApiUrls.paymentsSubscriptionCancel, {});
  }

  resumeSubscription(): Observable<void> {
    return this.http.post<void>(ApiUrls.paymentsSubscriptionResume, {});
  }

  changePackage(subscriptionId: number, additional: unknown[] = []): Observable<void> {
    return this.http.post<void>(ApiUrls.paymentsSubscriptionChange, {
      subscription_pricing_id: subscriptionId,
      additional_pricing: additional,
    });
  }

  addProducts(products: { id: number; count: number }[]): Observable<void> {
    return this.http.post<void>(ApiUrls.paymentsSunscriptionProductsBuy, { products });
  }

  removeProducts(id: number, count: number): Observable<void> {
    return this.http.post<void>(ApiUrls.paymentsSunscriptionProductsRemove, { products: [{ id, count }] });
  }

  getPaymentData(paymentId: number): Observable<PaymentDataModel> {
    return this.http.get<PaymentDataModel>(ApiUrls.paymentsById.prepareUrl({ paymentId }));
  }

  removeCard(): Observable<void> {
    return this.http.delete<void>(ApiUrls.paymentsMethods);
  }

  reconnectCard(payment_method: string): Observable<{ cardBrand: string; lastFour: string }> {
    return this.http.post<{ cardBrand: string; lastFour: string }>(ApiUrls.paymentsMethods, { payment_method });
  }

  isCountryMissing(): Observable<boolean> {
    return this.http.get<{ data?: { user?: { country?: string | null } } }>(ApiUrls.profile).pipe(
      map((response) => {
        return !response.data?.user?.country;
      }),
    );
  }

  getCountries(): Observable<Array<{ code: string; name: string }>> {
    return this.http.get<{ data: { items: { [key: string]: string } } }>(ApiUrls.dictsCountries).pipe(
      map((response) => {
        const { items } = response.data;
        return Object.entries(items).map(([code, name]) => ({
          code,
          name,
        }));
      }),
    );
  }

  private mapCurrentPackage(current: CurrentPackage) {
    current.isIncomplete = current.stripe_status === StripeStatusEnum.incomplete;
    current.isCancelled = current.stripe_status === StripeStatusEnum.canceled || !!current.ends_at;
    current.isActive = current.stripe_status === StripeStatusEnum.active;
    current.isTrialling = current.stripe_status === StripeStatusEnum.trialing;
    current.isPastDue = current.stripe_status === StripeStatusEnum.pastDue;
    const mainPackage = current.items[0];
    current.isFremium = !(mainPackage && !current.isPastDue);
    current.name = mainPackage ? mainPackage.package_name : 'Free';

    current.items = current.items.map((item) => {
      item.prod_name = PROD_NAME(item);
      return item;
    });
  }

  private parsePackage(packageToParse: PaymentModel) {
    packageToParse.elements = {};
    Object.keys(packageToParse.restrictions).forEach((key) => {
      if (!this.elementsToDisplay.includes(key)) {
        return;
      }
      if (this.pluginService.isPluginMode) {
        if (key === 'projects') {
          return;
        }
      }

      const element = new PaymentElementModel(
        key,
        packageToParse.restrictions[key as keyof typeof packageToParse.restrictions] as string | boolean,
        'string',
      );
      packageToParse.elements[element.name] = element;
    });

    packageToParse.products.forEach((product) => {
      const restrictions = Object.entries(product.restrictions);
      restrictions.forEach((restriction) => {
        const key = restriction[0];
        const value = restriction[1];
        if (this.pluginService.isPluginMode) {
          if (key === 'projects') {
            return;
          }
          if (key === 'output_products') {
            return;
          }
        }
        const element = new PaymentElementModel(
          `${key}_product`,
          `+${product.price / 100}.00 ${product.currency.toUpperCase()}`,
          'string',
          `(+${value})`,
        );
        packageToParse.elements[element.name] = element;
      });
    });

    if (this.pluginService.isPluginMode) {
      packageToParse.elements.feed_optimalization_feature = new PaymentElementModel('feed_optimalization_feature', true, 'boolean');
      const outputProductsAvailable = +packageToParse.restrictions.output_products;
      packageToParse.elements.output_feeds_feature = new PaymentElementModel(
        'output_feeds_feature',
        outputProductsAvailable > 0,
        'boolean',
      );
    } else {
      packageToParse.elements.agregators_feature = new PaymentElementModel('agregators_feature', true, 'boolean');
      packageToParse.elements.bing_feature = new PaymentElementModel('bing_feature', true, 'boolean');
      packageToParse.elements.feed_optimalization_feature = new PaymentElementModel('feed_optimalization_feature', true, 'boolean');
      packageToParse.elements.sembot_css_feature = new PaymentElementModel('sembot_css_feature', packageToParse.price > 0, 'boolean');
      const outputProductsAvailable = +packageToParse.restrictions.output_products;
      packageToParse.elements.output_feeds_feature = new PaymentElementModel(
        'output_feeds_feature',
        outputProductsAvailable > 0,
        'boolean',
      );
      const campaignsRestriction = Number(packageToParse.restrictions.campaigns) !== 0;
      packageToParse.elements.campaigns_feature = new PaymentElementModel('campaigns_feature', campaignsRestriction, 'boolean');
    }

    return packageToParse;
  }

  private preparePlan(data: any): PaymentPlanModel {
    function prepare(obj: any, itHaveFreeProduct: boolean = false, learnMoreUrls: object | null = null): PaymentPlanProductsModel {
      const setups = filter(obj, (item) => item.prod_name === 'setup');

      const groupedProducts = groupBy(
        mapp(obj, (item) => {
          if (item.setup_id) {
            item.setup = pick(find(setups, { id: item.setup_id }), ['id', 'price']);
          }

          item.prod_name = PROD_NAME(item);

          return pick(item, PRODUCT_FIELDS);
        }),
        PROD_NAME,
      );

      itHaveFreeProduct && (groupedProducts.free = [{ price: 0, trial: 1 }]);

      return mapValues(groupedProducts, (products, key) => {
        let learnMoreUrl: string | undefined = undefined;
        let productPrice = null;
        let productTrial = false;

        if (Object.keys(products).length === 1) {
          const { price, trial } = products[0];
          productPrice = price;
          productTrial = trial;
        }

        if (learnMoreUrls && learnMoreUrls[key as keyof typeof learnMoreUrl]) {
          learnMoreUrl = learnMoreUrls[key as keyof typeof learnMoreUrl];
        }

        return {
          isTrial: productTrial,
          learnMoreUrl: learnMoreUrl,
          list: products as any,
          price: productPrice,
        };
      });
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { sembot, support, css, is_trial_available } = data || {};

    if (!sembot.length || !css.length) {
      return null!;
    }

    const basicSubscription = sembot[0];

    const { currency, products } = basicSubscription || {};

    const supportLearnMoreUrls = {
      [PaymentPlanSupportEnum.free]: this.translationService.commonUrls().landingPage,
      [PaymentPlanSupportEnum.activeService]: this.translationService.commonUrls().activeService,
      // @TMP: temporary removal of All in one: #asana-1207432193547201
      // [PaymentPlanSupportEnum.allInOne]: this.translationService.commonUrls().allInOne,
    };

    const supportPrepared = support.length ? prepare(support, true, supportLearnMoreUrls) : undefined;

    return {
      currency: currency || CurrencyEnum.pln,
      is_trial_available,
      sembot: prepare(products),
      css: prepare(css, true),
      support: supportPrepared,
      basic: pick(basicSubscription, ['id', 'price', 'prod_name', 'restrictions', 'setup', 'trial']),
    };
  }
}
