import { Component, signal, Inject } from '@angular/core';
import { MatDialogContent, MatDialogActions, MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

interface TemplateErrorData {
  message: string;
  errors: Record<string, string[]>;
}

@Component({
  selector: 'app-template-errors-dialog',
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, MatDialogTitle, TranslatePipe, MatButton],
  templateUrl: './template-errors-dialog.component.html',
  styleUrl: './template-errors-dialog.component.scss',
})
export class TemplateErrorsDialogComponent {
  readonly dataMessage = signal(this.data?.message || 'Error');
  readonly dataErrors = signal(this.data?.errors || {});

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TemplateErrorData,
    private dialogRef: MatDialogRef<TemplateErrorsDialogComponent>,
  ) {}

  errorKeys() {
    return Object.keys(this.dataErrors());
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
