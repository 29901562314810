"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = void 0;
var Features_1 = require("@shopify/app-bridge-core/actions/Features");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Features_1.Action;
  }
});