"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.ButtonGroup = exports.isGroupedButtonPayload = exports.isGroupedButton = exports.update = exports.Action = void 0;
var ButtonGroup_1 = require("@shopify/app-bridge-core/actions/ButtonGroup");
Object.defineProperty(exports, "ButtonGroup", {
  enumerable: true,
  get: function () {
    return ButtonGroup_1.ButtonGroup;
  }
});
var ButtonGroup_2 = require("@shopify/app-bridge-core/actions/ButtonGroup");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return ButtonGroup_2.Action;
  }
});
Object.defineProperty(exports, "update", {
  enumerable: true,
  get: function () {
    return ButtonGroup_2.update;
  }
});
Object.defineProperty(exports, "isGroupedButton", {
  enumerable: true,
  get: function () {
    return ButtonGroup_2.isGroupedButton;
  }
});
Object.defineProperty(exports, "isGroupedButtonPayload", {
  enumerable: true,
  get: function () {
    return ButtonGroup_2.isGroupedButtonPayload;
  }
});
function create(app, options) {
  return new ButtonGroup_1.ButtonGroup(app, options);
}
exports.create = create;