"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initializeWebVitals = exports.interactionToNextPaint = exports.timeToFirstByte = exports.firstContentfulPaint = exports.cumulativeLayoutShift = exports.firstInputDelay = exports.largestContentfulPaint = exports.Action = void 0;
var web_vitals_1 = require("web-vitals");
var WebVitals_1 = require("@shopify/app-bridge-core/actions/WebVitals");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return WebVitals_1.Action;
  }
});
var env_1 = require("../../util/env");
var types_1 = require("../types");
var helper_1 = require("../helper");
var platform_1 = require("../../utilities/platform");
function largestContentfulPaint(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.WebVitals,
    type: WebVitals_1.Action.LARGEST_CONTENTFUL_PAINT,
    payload: payload
  });
}
exports.largestContentfulPaint = largestContentfulPaint;
function firstInputDelay(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.WebVitals,
    type: WebVitals_1.Action.FIRST_INPUT_DELAY,
    payload: payload
  });
}
exports.firstInputDelay = firstInputDelay;
function cumulativeLayoutShift(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.WebVitals,
    type: WebVitals_1.Action.CUMULATIVE_LAYOUT_SHIFT,
    payload: payload
  });
}
exports.cumulativeLayoutShift = cumulativeLayoutShift;
function firstContentfulPaint(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.WebVitals,
    type: WebVitals_1.Action.FIRST_CONTENTFUL_PAINT,
    payload: payload
  });
}
exports.firstContentfulPaint = firstContentfulPaint;
function timeToFirstByte(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.WebVitals,
    type: WebVitals_1.Action.TIME_TO_FIRST_BYTE,
    payload: payload
  });
}
exports.timeToFirstByte = timeToFirstByte;
function interactionToNextPaint(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.WebVitals,
    type: WebVitals_1.Action.INTERACTION_TO_NEXT_PAINT,
    payload: payload
  });
}
exports.interactionToNextPaint = interactionToNextPaint;
function initializeWebVitals(app) {
  function onReport(cb) {
    return function (data) {
      var id = data.id,
        metricName = data.name,
        value = data.value;
      var payload = {
        id: id,
        metricName: metricName,
        value: value
      };
      var event = cb(payload);
      app.dispatch(event);
    };
  }
  var untypedWindow = window;
  if (env_1.isServer || env_1.isClient && untypedWindow.__is_web_vitals_initialized__ || platform_1.isMobile()) {
    // We need to prevent the Web Vitals functions from being called more than once per page load,
    // which can cause a memory leak as per the web-vitals docs.
    return;
  }
  untypedWindow.__is_web_vitals_initialized__ = true;
  web_vitals_1.onLCP(onReport(largestContentfulPaint));
  web_vitals_1.onFID(onReport(firstInputDelay));
  web_vitals_1.onCLS(onReport(cumulativeLayoutShift));
  web_vitals_1.onFCP(onReport(firstContentfulPaint));
  web_vitals_1.onTTFB(onReport(timeToFirstByte));
  web_vitals_1.onINP(onReport(interactionToNextPaint));
}
exports.initializeWebVitals = initializeWebVitals;