"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AppLink = exports.update = exports.Action = void 0;
var helper_1 = require("../../helper");
var ActionSet_1 = require("../../ActionSet");
var types_1 = require("../../types");
var Redirect_1 = require("../../Navigation/Redirect");
var Action;
(function (Action) {
  Action["UPDATE"] = "UPDATE";
})(Action = exports.Action || (exports.Action = {}));
function update(group, component, updatePayload) {
  var id = component.id;
  var label = updatePayload.label,
    destination = updatePayload.destination;
  var linkPayload = __assign(__assign({}, updatePayload), {
    id: id,
    label: label,
    destination: destination
  });
  return helper_1.actionWrapper({
    group: group,
    type: helper_1.getEventNameSpace(group, Action.UPDATE, component),
    payload: linkPayload
  });
}
exports.update = update;
var AppLink = /** @class */function (_super) {
  __extends(AppLink, _super);
  function AppLink(app, options) {
    var _this = _super.call(this, app, types_1.Group.Link, types_1.Group.Link) || this;
    _this.label = '';
    _this.destination = '';
    _this.set(options, false);
    return _this;
  }
  Object.defineProperty(AppLink.prototype, "options", {
    get: function () {
      var _a = this,
        label = _a.label,
        destination = _a.destination;
      return {
        label: label,
        destination: destination,
        redirectType: Redirect_1.Action.APP
      };
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(AppLink.prototype, "payload", {
    get: function () {
      var _a = this.options,
        label = _a.label,
        destination = _a.destination,
        redirectType = _a.redirectType;
      var path = destination;
      return {
        id: this.id,
        label: label,
        destination: {
          path: path
        },
        redirectType: redirectType
      };
    },
    enumerable: false,
    configurable: true
  });
  AppLink.prototype.set = function (options, shouldUpdate) {
    if (shouldUpdate === void 0) {
      shouldUpdate = true;
    }
    var _a = helper_1.getMergedProps(this.options, options),
      label = _a.label,
      destination = _a.destination;
    this.label = label;
    this.destination = destination;
    if (shouldUpdate) {
      this.dispatch(Action.UPDATE);
    }
    return this;
  };
  AppLink.prototype.dispatch = function (action) {
    switch (action) {
      case Action.UPDATE:
        {
          var updateAction = update(this.group, this.component, this.payload);
          this.app.dispatch(updateAction);
          break;
        }
    }
    return this;
  };
  return AppLink;
}(ActionSet_1.ActionSet);
exports.AppLink = AppLink;