"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFromApp = exports.isPerformanceOrWebVitalsAction = exports.getPermissionKey = exports.isPermitted = exports.isAppMessage = exports.isAppBridgeAction = void 0;
var validator_1 = require("@shopify/app-bridge-core/actions/validator");
Object.defineProperty(exports, "isAppBridgeAction", {
  enumerable: true,
  get: function () {
    return validator_1.isAppBridgeAction;
  }
});
Object.defineProperty(exports, "isAppMessage", {
  enumerable: true,
  get: function () {
    return validator_1.isAppMessage;
  }
});
Object.defineProperty(exports, "isPermitted", {
  enumerable: true,
  get: function () {
    return validator_1.isPermitted;
  }
});
Object.defineProperty(exports, "getPermissionKey", {
  enumerable: true,
  get: function () {
    return validator_1.getPermissionKey;
  }
});
Object.defineProperty(exports, "isPerformanceOrWebVitalsAction", {
  enumerable: true,
  get: function () {
    return validator_1.isPerformanceOrWebVitalsAction;
  }
});
/**
 * Predicate to determine if an action originated from an application.
 * @internal
 */
function isFromApp(action) {
  if (typeof action !== 'object' || typeof action.source !== 'object') {
    return false;
  }
  return typeof action.source.apiKey === 'string';
}
exports.isFromApp = isFromApp;