"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fullPageLoad = exports.skeletonPageLoad = exports.Action = void 0;
var types_1 = require("../types");
var helper_1 = require("../helper");
var Action;
(function (Action) {
  Action["SKELETON_PAGE_LOAD"] = "APP::PERFORMANCE::SKELETON_PAGE_LOAD";
  Action["FULL_PAGE_LOAD"] = "APP::PERFORMANCE::FULL_PAGE_LOAD";
})(Action = exports.Action || (exports.Action = {}));
function skeletonPageLoad() {
  return helper_1.actionWrapper({
    group: types_1.Group.Performance,
    type: Action.SKELETON_PAGE_LOAD
  });
}
exports.skeletonPageLoad = skeletonPageLoad;
function fullPageLoad() {
  return helper_1.actionWrapper({
    group: types_1.Group.Performance,
    type: Action.FULL_PAGE_LOAD
  });
}
exports.fullPageLoad = fullPageLoad;