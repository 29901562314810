"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateUuid = void 0;
var uuid_1 = require("@shopify/app-bridge-core/actions/uuid");
Object.defineProperty(exports, "generateUuid", {
  enumerable: true,
  get: function () {
    return uuid_1.generateUuid;
  }
});
exports.default = uuid_1.generateUuid;