import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { environment } from 'src/environments/environment';
import { ProjectService } from '../dashboard/project/project.service';
import { FormApiValidationError } from '../shared/model/errors/formApiError.model';
import { LocalStorageService } from '../shared/service/local-storage.service';

export const ERROR_NOTIFICATION_SKIP = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ErrorIntercecptorService implements HttpInterceptor {
  private tokenExpired$: Subject<any> = new Subject();
  private skipErrorNotification = false;

  constructor(
    private dialogRef: MatDialog,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private routerService: RouterService,
    private authService: AuthService,
    private storageHelperService: LocalStorageService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.skipErrorNotification = req.context.get(ERROR_NOTIFICATION_SKIP) === true;

    return next.handle(req).pipe(
      takeUntil(this.tokenExpired$),
      catchError((error: HttpErrorResponse) => this.handleHttpError(error)),
    );
  }

  private handleHttpError(httpError: HttpErrorResponse) {
    const { error, status, url } = httpError;
    const errorMessage = error.message || null;

    // Check if is external url, ex. marketer AI
    if (url && !url.includes(environment.apiUrl)) {
      this.notifyError('unknown_error_occurred', false);
      return throwError(error);
    }

    switch (status) {
      case 400:
        return throwError(error);

      case 401:
        this.logout();
        this.tokenExpired$.next(null);
        return throwError(error);

      case 403:
      case 404:
        this.notifyError(errorMessage);
        return throwError(error);

      case 422: {
        return throwError(new FormApiValidationError(error, this.notificationService));
      }
      case 455: // Maintenance
        this.notifyError('maintenance_start', false);
        this.routerService.navigate(routerConfig.maintenance);
        this.tokenExpired$.next(null);
        return throwError(error);
      case 460:
        this.notifyError(errorMessage);
        return throwError(error);

      case 461:
        this.notifyError(errorMessage);
        return throwError(error);

      case 462: // API bussines error
        this.notifyError(errorMessage);
        return throwError(error);
      case 463:
        return throwError(error);
      case 464: // RESTRICTION_ERROR
        if (this.authService.authUser!.permissions!.some((perm) => perm === 'manage payment')) {
          this.routerService.navigate(routerConfig.paymentsAddProducts);
        }
        this.notifyError(errorMessage);
        return throwError(error);
      case 465: // NO_SUBSCRIPTION_ERROR
        this.routerService.navigate(routerConfig.paymentsPlan);
        this.notifyError(errorMessage);
        return throwError(error);
      default:
        this.notifyError('unknown_error_occurred', false);
        return throwError(error);
    }
  }

  private notifyError(errorMessage: any, dontCheckKey = true) {
    !this.skipErrorNotification && this.notificationService.error(errorMessage, {}, dontCheckKey);
  }

  private logout(errorTranslateKey = 'session_expired', route = routerConfig['/']) {
    this.dialogRef.closeAll();
    this.authService.removeToken();
    this.projectService.activeProject$.next(null!);
    this.routerService.navigate(route);
    this.notifyError(errorTranslateKey, false);
  }
}
