"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unsubscribeActions = exports.ActionSetWithChildren = exports.ActionSet = void 0;
var ActionSet_1 = require("@shopify/app-bridge-core/actions/ActionSet");
Object.defineProperty(exports, "ActionSet", {
  enumerable: true,
  get: function () {
    return ActionSet_1.ActionSet;
  }
});
Object.defineProperty(exports, "ActionSetWithChildren", {
  enumerable: true,
  get: function () {
    return ActionSet_1.ActionSetWithChildren;
  }
});
Object.defineProperty(exports, "unsubscribeActions", {
  enumerable: true,
  get: function () {
    return ActionSet_1.unsubscribeActions;
  }
});