"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.isValidButtonProps = exports.Button = exports.update = exports.Style = exports.Icon = exports.clickButton = exports.Action = void 0;
var Button_1 = require("@shopify/app-bridge-core/actions/Button");
Object.defineProperty(exports, "Button", {
  enumerable: true,
  get: function () {
    return Button_1.Button;
  }
});
var Button_2 = require("@shopify/app-bridge-core/actions/Button");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Button_2.Action;
  }
});
Object.defineProperty(exports, "clickButton", {
  enumerable: true,
  get: function () {
    return Button_2.clickButton;
  }
});
Object.defineProperty(exports, "Icon", {
  enumerable: true,
  get: function () {
    return Button_2.Icon;
  }
});
Object.defineProperty(exports, "Style", {
  enumerable: true,
  get: function () {
    return Button_2.Style;
  }
});
Object.defineProperty(exports, "update", {
  enumerable: true,
  get: function () {
    return Button_2.update;
  }
});
function isValidButtonProps(button) {
  return typeof button.id === 'string' && typeof button.label === 'string';
}
exports.isValidButtonProps = isValidButtonProps;
function create(app, options) {
  return new Button_1.Button(app, options);
}
exports.create = create;